import React, { useState, useEffect, createRef } from "react";
import { useSelector, useDispatch } from "react-redux";
// import markerIcon from "../../assets/images/marker.svg";

import { Container, Row, Col } from "reactstrap";

import Layout from "../../layout";
import SEO from "../../components/seo";
import Heading from "../../components/atoms/Heading/Heading";
import Button from "../../components/atoms/Button/Button";
import Icon from "../../components/atoms/Icon/Icon";

import RouteCards from "../../components/organisms/RouteCards/RouteCards";
import MapDisplay from "../../components/molecules/MapDisplay/MapDisplay";
import RoutesSlider from "../../components/organisms/RoutesSlider/RoutesSlider";

// ACTIONS
import {
    showHeader,
    showBottomNav,
    hideHeader,
    hideBottomNav,
    setCurrentRoute,
} from "../../state/actions";

// DATA
import useRoutes from "../../hooks/redux/useRoutes";

const { useMap } =
    typeof window !== "undefined" ? require("react-leaflet") : {};

let mapRef = "";

const placeToCoord = ({ lon = 0, lat = 0 }) => [lon - 0.02, lat];

const LocationChange = () => {
    const map = useMap();
    const routeIndex = useSelector((state) => state.route.currentRoute);
    const [{ routes }] = useRoutes();
    const route = routes[routeIndex ?? 0];

    mapRef = map;

    useEffect(() => {
        const setViewOnStateChange = () => {
            map.setView(placeToCoord(route.places[0] ?? {}), 12);
        };

        if (route) {
            // setViewOnStateChange();

            const locations = route.places.map((a) => {
                return [a.lon - 0.003, a.lat];
            });

            map.fitBounds(locations, {
                padding: [50, 150],
            });
        }
    }, [routeIndex]);

    return null;
};

const Routes = () => {
    const dispatch = useDispatch();
    const [mapView, setMapView] = useState(false);
    const [height, setHeight] = useState(0);
    const sliderRef = createRef();
    const [{ routes }] = useRoutes();
    const route = routes[0];

    useEffect(() => {
        if (typeof window !== undefined) {
            setHeight(window.innerHeight);

            window.addEventListener("resize", () => {
                setHeight(window.innerHeight);
            });
        }
    }, []);

    useEffect(() => {
        if (mapView) {
            dispatch(hideHeader());
            dispatch(hideBottomNav());
            mapRef && mapRef.invalidateSize();

            sliderRef.current.slickGoTo(0);
            dispatch(setCurrentRoute(0));

            const locations = route.places.map((a) => {
                return [a.lon - 0.003, a.lat];
            });

            mapRef.fitBounds(locations, {
                padding: [50, 150],
            });
        } else {
            dispatch(showHeader());
            dispatch(showBottomNav());
        }
    }, [mapView]);

    return (
        <Layout>
            <SEO title="Trasy" />
            <Container
                style={{
                    marginBottom: "9rem",
                    display: !mapView ? "block" : "none",
                }}
            >
                <Row className="justify-content-center">
                    <Col lg={10}>
                        <Heading className="text-center" as="h1">
                            Aktywni Razem
                        </Heading>
                    </Col>
                </Row>
                <Row className="justify-content-center">
                    <Col lg={10}>
                        <div className="btn--group">
                            <Button className="btn--primary">Lista</Button>
                            <Button
                                className="btn--secondary"
                                onClick={() => {
                                    setMapView(true);
                                }}
                            >
                                <Icon className="icon-map-min" />
                                Mapa
                            </Button>
                        </div>
                    </Col>
                </Row>
                <RouteCards />
            </Container>

            <div
                className="position-relative"
                style={{ height: height, display: mapView ? "block" : "none" }}
            >
                <MapDisplay
                    sliderRef={sliderRef}
                    setMapView={setMapView}
                    whenCreated={(mapInstance) => {
                        mapRef = mapInstance;
                    }}
                >
                    <LocationChange />
                </MapDisplay>
                <RoutesSlider
                    sliderRef={sliderRef}
                    position="absolute"
                    mapRef={mapRef}
                />
            </div>
        </Layout>
    );
};

export default Routes;
