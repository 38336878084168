import React, { useEffect } from "react";

// STYLE
import "leaflet/dist/leaflet.css";
import "./mapDisplay.scss";

// COMPONENTS
import { Container, Row, Col } from "reactstrap";
import Button from "../../atoms/Button/Button";
import Icon from "../../atoms/Icon/Icon";
import { useDispatch, useSelector } from "react-redux";
import { showBottomNav, showHeader } from "../../../state/actions";
import useRoutes from "../../../hooks/redux/useRoutes";
import LocationMarker from "../../atoms/LocationMarker/LocationMarker";
import PlaceMarker from "../../atoms/PlaceMarker/PlaceMarker";
import * as LUtils from "../../../utils/leafletUtils";
import { useLocationDeniedModal } from "../../../hooks/redux";

// import { MapContainer, Polyline, TileLayer, useMap } from "react-leaflet";
const { MapContainer, Polyline, TileLayer, useMap } =
    typeof window !== "undefined" ? require("react-leaflet") : {};

const MapControls = ({ setMapView }) => {
    const map = useMap();
    const [locationDeniedModal, { showLocationDeniedModal }] =
        useLocationDeniedModal();

    const getLocation = () => {
        if (localStorage.getItem("locationStatus") === "denied") {
            showLocationDeniedModal();
        } else {
            map.locate({ enableHighAccuracy: true, maximumAge: 0 });
        }
    };

    return (
        <Container>
            <Row>
                <Col>
                    <div className="map-display--actions">
                        <Button
                            className="btn--circle page-header--action-btn"
                            onClick={() => {
                                setMapView(false);
                            }}
                            aria-label="Cofnij do poprzedniego widoku"
                        >
                            <Icon className="icon-chevron-left-min"></Icon>
                        </Button>
                        <Button
                            className="btn--circle page-header--action-btn btn--locate-me"
                            onClick={getLocation}
                        >
                            <svg x="0px" y="0px" viewBox="-391 269.3 17.1 24.4">
                                <g transform="translate(-287 -38)">
                                    <g>
                                        <path
                                            d="M-92.3,324.8l0.4-2.6l1.6-1c0.3-0.2,0.4-0.5,0.4-0.8l-0.6-4.2c-0.2-1.6-1.6-2.9-3.2-3
                                            c1.5-1,1.9-2.9,0.9-4.4s-2.9-1.9-4.4-0.9c-1.5,1-1.9,2.9-0.9,4.4c0.2,0.4,0.6,0.7,0.9,0.9c-1.6,0.1-3,1.4-3.2,3l-0.6,4.2
                                            c0,0.3,0.1,0.6,0.4,0.8l1.6,1l0.4,2.7c-2.8,0.4-5.2,1.4-5.2,3.1c0,2.3,4.4,3.4,8.5,3.4c4.1,0,8.5-1.1,8.5-3.4
                                            C-86.9,326.2-89.3,325.2-92.3,324.8z M-95.5,308.9c0.9,0,1.6,0.7,1.7,1.6c0,0.9-0.7,1.6-1.6,1.7c-0.9,0-1.6-0.7-1.7-1.6
                                            c0,0,0,0,0,0C-97.1,309.6-96.4,308.9-95.5,308.9z M-99.4,320.1l0.5-3.7c0.1-0.9,0.8-1.6,1.7-1.6h3.3c0.9,0.1,1.6,0.8,1.7,1.6
                                            l0.5,3.7L-93,321c-0.2,0.1-0.3,0.3-0.4,0.6l-0.8,5.8v0c0,0.1-0.1,0.2-0.2,0.3h-2.1c-0.1,0-0.2-0.1-0.2-0.3v0l-0.8-5.8
                                            c0-0.2-0.2-0.4-0.4-0.6L-99.4,320.1z M-90.2,329c-1.7,0.5-3.5,0.7-5.2,0.7c-1.8,0-3.5-0.2-5.2-0.7c-1.3-0.4-1.7-0.9-1.7-1.1
                                            s0.4-0.6,1.7-1.1c0.7-0.2,1.4-0.4,2.1-0.5l0.2,1.3c0.1,0.9,0.9,1.6,1.8,1.6h2.1c0.9,0,1.7-0.7,1.8-1.6l0.2-1.3
                                            c0.8,0.1,1.6,0.3,2.3,0.5c1.3,0.4,1.7,0.9,1.7,1.1S-88.9,328.6-90.2,329L-90.2,329z"
                                        />
                                    </g>
                                </g>
                            </svg>
                        </Button>
                    </div>
                </Col>
            </Row>
        </Container>
    );
};

const placeToCoord = ({ lon = 0, lat = 0 }) => [lon, lat];

const MapDisplay = ({ sliderRef, setMapView, children }) => {
    const dispatch = useDispatch();
    const routeIndex = useSelector((state) => state.route.currentRoute);
    const [{ routes }] = useRoutes();
    const route = routes?.[routeIndex ?? 0];

    const markers = routes?.map((route, index) => (
        <PlaceMarker
            key={index}
            position={[route.places[0].lon, route.places[0].lat]}
            icon={LUtils.getMarkerIcon(route)}
            eventHandlers={{
                click: (_) => {
                    sliderRef.current.slickGoTo(index);
                },
            }}
        />
    ));

    const paths = routes?.map((route, index) => {
        return (
            <Polyline
                key={index}
                positions={[...route.places, route.places[0]].map(
                    ({ lat, lon }) => [lon, lat]
                )}
                pathOptions={{
                    color: LUtils.getColor(route),
                    opacity: routeIndex === index ? 1 : 0,
                }}
            />
        );
    });

    const center = route?.places
        ?.map(({ lon, lat }) => [lon, lat])
        ?.reduce(([latA, lonA], [latB, lonB]) => [
            (latA + latB) / 2,
            (lonA + lonB) / 2,
        ]) ?? [0, 0];

    return route ? (
        <MapContainer
            center={center}
            zoom={11}
            doubleClickZoom={false}
            closePopupOnClick={false}
            dragging={true}
            zoomSnap={false}
            zoomDelta={false}
            trackResize={true}
            touchZoom={true}
            scrollWheelZoom={false}
            style={{ height: "100%", zIndex: 1 }}
            className="map-display"
        >
            <TileLayer
                attribution='&copy; <a href="https://stadiamaps.com/">Stadia Maps</a>, &copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
                url="https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png?api_key=1a10a4bf-8b40-4f57-8c93-0129034a2537"
            />
            <MapControls setMapView={setMapView} />
            {children}
            {markers}
            {paths}
            <LocationMarker />
        </MapContainer>
    ) : (
        ""
    );
};

export default MapDisplay;
